<template>
  <div>
    <ElRow :gutter="20" justify="center" align="middle">
      <ElCol :span="24" :xs="24" :lg="24">
        <ElForm
          require-asterisk-position="right"
          ref="ruleFormRef"
          :rules="inputRules"
          :model="form"
          label-position="top"
        >
          <ElRow :gutter="20">
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="brand" :label="$t('brand')">
                <ElSelect
                  v-model="form.brand"
                  :placeholder="$t('select_an_option')"
                  class="w-full"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  clearable
                  clear-icon="Close"
                >
                  <el-option
                    v-for="item in brandTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </ElSelect>
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem
                prop="expiration_month"
                :label="$t('expiration_month')"
              >
                <ElSelect
                  v-model="form.expiration_month"
                  :placeholder="$t('select_an_option')"
                  class="w-full"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  clearable
                  clear-icon="Close"
                >
                  <el-option
                    v-for="item in [
                      '1',
                      '2',
                      '3',
                      '4',
                      '5',
                      '6',
                      '7',
                      '8',
                      '9',
                      '10',
                      '11',
                      '12'
                    ]"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </ElSelect>
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="expiration_year" :label="$t('expiration_year')">
                <ElSelect
                  v-model="form.expiration_year"
                  :placeholder="$t('select_an_option')"
                  class="w-full"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  clearable
                  clear-icon="Close"
                >
                  <el-option
                    v-for="item in expirationYear"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </ElSelect>
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="cvc" :label="$t('cvc')">
                <ElInput
                  v-model="form.cvc"
                  @keydown.enter.prevent="submit(ruleFormRef)"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem
                prop="last_four_digits"
                :label="$t('last_four_digits')"
              >
                <ElInput
                  v-model="form.last_four_digits"
                  @keydown.enter.prevent="submit(ruleFormRef)"
                />
              </ElFormItem>
            </ElCol>
            <ElCol
              :span="containerWidth <= 700 ? 24 : 12"
              :lg="12"
              v-if="!props.modelType"
            >
              <ElFormItem prop="model_type" :label="$t('model_type')">
                <ElSelect
                  v-model="form.model_type"
                  :placeholder="$t('select_a_type')"
                  style="width: 100%"
                  @change="form.model_uuid = ''"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  clearable
                  clear-icon="Close"
                >
                  <el-option
                    v-for="item in modelTypes"
                    :key="item"
                    :label="
                      item === 'LimitedUser'
                        ? $t('user')
                        : $t(item.toLowerCase())
                    "
                    :value="item"
                  />
                </ElSelect>
              </ElFormItem>
            </ElCol>
            <ElCol
              v-if="form.model_type === 'Company' && !props.modelType"
              :span="containerWidth <= 700 ? 24 : 12"
              :lg="12"
            >
              <ElFormItem
                prop="model_uuid"
                :label="$t('company')"
                class="autocomplete-new-entity"
                :class="form.model_uuid ? 'autocomplete-redirection' : ''"
              >
                <el-select-v2
                  :key="getCompanyKey()"
                  v-loading="getCompanyKey() === 0"
                  v-model="form.model_uuid"
                  :remote-method="searchCompanies"
                  :placeholder="$t('select_a_company')"
                  remote
                  filterable
                  autocomplete
                  clearable
                  clear-icon="Close"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  :options="getSearchCompanies()"
                  style="width: 100%"
                />
                <Icons
                  name="24px/group_purple"
                  class="new-entity-button"
                  @click="showNewCompanyDialog = true"
                />
                <Icons
                  v-if="form.model_uuid"
                  name="24px/arrow_top_right_purple"
                  class="new-entity-button"
                  @click="
                    newWindowItem('/financal/companies', {
                      uuid: form.model_uuid
                    })
                  "
                />
              </ElFormItem>
            </ElCol>
            <ElCol
              v-if="form.model_type === 'LimitedUser' && !props.modelType"
              :span="containerWidth <= 700 ? 24 : 12"
              :lg="12"
            >
              <ElFormItem
                prop="model_uuid"
                :label="$t('user')"
                class="autocomplete-new-entity"
                :class="form.model_uuid ? 'autocomplete-redirection' : ''"
              >
                <el-select-v2
                  :key="getUserKey()"
                  v-loading="getUserKey() === 0"
                  v-model="form.model_uuid"
                  :remote-method="searchUsers"
                  :placeholder="$t('select_an_user')"
                  remote
                  filterable
                  autocomplete
                  clearable
                  clear-icon="Close"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  :options="getSearchUsers()"
                  style="width: 100%"
                />
                <Icons
                  name="24px/group_purple"
                  class="new-entity-button"
                  @click="showNewUserDialog = true"
                />
                <Icons
                  v-if="form.model_uuid"
                  name="24px/arrow_top_right_purple"
                  class="new-entity-button"
                  @click="
                    newWindowItem('/configuration/users', {
                      uuid: form.model_uuid
                    })
                  "
                />
              </ElFormItem>
            </ElCol>
            <ElCol
              v-if="form.model_type === 'Customer' && !props.modelType"
              :span="containerWidth <= 700 ? 24 : 12"
              :lg="12"
            >
              <ElFormItem
                prop="model_uuid"
                :label="$t('customer')"
                class="autocomplete-new-entity"
                :class="form.model_uuid ? 'autocomplete-redirection' : ''"
              >
                <el-select-v2
                  :key="getCustomerKey()"
                  v-loading="getCustomerKey() === 0"
                  v-model="form.model_uuid"
                  :remote-method="searchCustomers"
                  :placeholder="$t('select_an_option')"
                  remote
                  filterable
                  autocomplete
                  clearable
                  clear-icon="Close"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  :options="getSearchCustomers()"
                  style="width: 100%"
                />
                <Icons
                  name="24px/group_purple"
                  class="new-entity-button"
                  @click="showNewCustomerDialog = true"
                />
                <Icons
                  v-if="form.model_uuid"
                  name="24px/arrow_top_right_purple"
                  class="new-entity-button"
                  @click="
                    newWindowItem('/financal/customers', {
                      uuid: form.model_uuid
                    })
                  "
                />
              </ElFormItem>
            </ElCol>
            <ElCol
              v-if="
                form.model_type === 'Company' ||
                form.model_type === 'LimitedUser' ||
                form.model_type === 'Customer'
              "
              :span="containerWidth <= 700 ? 24 : 12"
              :lg="12"
            />
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="cancel-button"
            >
              <ElButton
                style="width: 100%"
                type="secondary"
                @click="$emit('cancel')"
              >
                {{ $t('cancel') }}
              </ElButton>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="confirm-button"
            >
              <ElButton
                :loading="loading"
                :disabled="loading"
                type="primary"
                style="width: 100%"
                @click="submit(ruleFormRef)"
              >
                {{ $t('save') }}
              </ElButton>
            </ElCol>
          </ElRow>
        </ElForm>
      </ElCol>
    </ElRow>
    <el-dialog
      v-model="showNewUserDialog"
      :width="containerWidth <= 600 ? '100%' : '627px'"
      :close-on-click-modal="false"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_user') }}
        </span>
      </template>
      <QuickCreateUserDialog
        @create=";(showNewUserDialog = false), createUser($event)"
        @cancel="showNewUserDialog = false"
      />
    </el-dialog>
    <el-dialog
      v-model="showNewCustomerDialog"
      :width="containerWidth <= 600 ? '100%' : '627px'"
      :close-on-click-modal="false"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_customer') }}
        </span>
      </template>
      <QuickCreateCustomerDialog
        @create=";(showNewCustomerDialog = false), createCustomer($event)"
        @cancel="showNewCustomerDialog = false"
      />
    </el-dialog>
  </div>
</template>

<script setup>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CREATE_CREDIT_CARD from '~/graphql/credit-cards/mutation/createCreditCard.gql'

const { t } = useI18n()
const { $showError, $showSuccess, $sentry } = useNuxtApp()

const props = defineProps({
  modelType: {
    type: String,
    required: false,
    default: ''
  },
  model: {
    type: Object,
    required: false,
    default: () => ({})
  }
})

const form = reactive({
  brand: '',
  last_four_digits: '',
  expiration_month: '',
  expiration_year: '',
  cvc: '',
  model_type: props.modelType || '',
  model_uuid: props.model?.uuid || ''
})

onMounted(() => {
  if (props.modelType === 'Customer') {
    createCustomer(props.model)
  } else if (props.modelType === 'LimitedUser') {
    createUser(props.model)
  } else if (props.modelType === 'Company') {
    createCompany(props.model)
  }
})

const emits = defineEmits(['create'])
const { width } = useBreakpoints()
let { newWindowItem } = getTableInitialVariables()

const ruleFormRef = ref()
const loading = ref(false)
const showNewCompanyDialog = ref(false)
const showNewUserDialog = ref(false)
const showNewCustomerDialog = ref(false)
const brandTypes = getCreditCardBrandTypes()

const rules = {
  brand: { required },
  last_four_digits: { required, maxLength: 4 },
  expiration_month: { required },
  expiration_year: { required },
  cvc: { required, minLength: 3, maxLength: 4 },
  model_type: { required },
  model_uuid: { required }
}

onMounted(() => {
  initialCompanies()
  initialCustomers()
  initialUsers()
})

const v$ = useVuelidate(rules, form)

const inputRules = reactive({
  brand: [
    {
      required: true,
      message: t('brand_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  last_four_digits: [
    {
      required: true,
      message: t('last_four_digits_is_required'),
      trigger: ['blur', 'change']
    },
    {
      max: 4,
      message: t('max_length_is', { max: 4, field: t('last_four_digits') }),
      trigger: ['blur', 'change']
    }
  ],
  expiration_month: [
    {
      required: true,
      message: t('expiration_month_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  expiration_year: [
    {
      required: true,
      message: t('expiration_year_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  cvc: [
    {
      required: true,
      message: t('cvc_is_required'),
      trigger: ['blur', 'change']
    },
    {
      min: 3,
      max: 4,
      message: t('length_must_be_between', { min: 3, max: 4 }),
      trigger: ['blur', 'change']
    }
  ],
  model_type: [
    {
      required: true,
      message: t('model_type_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  model_uuid: [
    {
      required: true,
      message: t('model_uuid_is_required'),
      trigger: ['blur', 'change']
    }
  ]
})

const modelTypes = computed(() => {
  return ['Company', 'LimitedUser', 'Customer']
})

const expirationYear = computed(() => {
  let years = []
  for (
    let i = new Date().getFullYear();
    i < new Date().getFullYear() + 50;
    i++
  ) {
    years.push(i.toString())
  }
  return years
})

const submit = async (formEl) => {
  v$.value.$touch()
  await formEl.validate((valid, fields) => {
    if (valid) {
      create()
    } else {
      $showError(t('form_is_invalid'))
    }
  })
}

const create = async () => {
  loading.value = true
  const response = await mutation(CREATE_CREDIT_CARD, {
    brand: form.brand,
    last_four_digits: form.last_four_digits,
    expiration_month: form.expiration_month,
    expiration_year: form.expiration_year,
    cvc: form.cvc,
    model_type: form.model_type,
    model_uuid: form.model_uuid
  })
  if (response.error) {
    loading.value = false
    $showError(response.error, t)
    $sentry(response.error, 'create')
  }
  if (response.result) {
    $showSuccess(t('new_entity_success'))
    v$.value.$reset()
    emits('create', response.result?.data?.createCreditCard ?? null)
    loading.value = false
  }
}

const createCompany = (event) => {
  if (event?.uuid) {
    form.model_uuid = event.uuid
    form.model = event
    searchCompanies(event.business_name, form.model, null, true)
  }
}
const createUser = (event) => {
  if (event?.uuid) {
    form.model_uuid = event.uuid
    form.model = event
    searchUsers(event.first_name, form.model, null, true)
  }
}

const createCustomer = (event) => {
  if (event?.uuid) {
    form.model_uuid = event.uuid
    form.model = event
    searchCustomers(event.first_name, form.model, null, true)
  }
}
</script>
